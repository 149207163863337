<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <mega-header :title="title" class="bg-warning text-white">
      <span class="text-muted d-block">{{ text }}</span>
    </mega-header>

    <div class="card-body">
      <slot></slot>

      <div class="navbar sm-down:flex-column-reverse md-up:justify-content-end">
        <mega-button
          class="btn-white sm-down:w-100 md-up:mr-2"
          @click="modal = false"
          >{{ $t("close") }}</mega-button
        >
        <mega-button
          class="btn-primary sm-down:w-100 sm-down:mb-3"
          @click="confirm"
          >{{ $t("redirect") }}</mega-button
        >
      </div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  props: {
    title: String,
    text: String
  },
  data() {
    return {
      modal: false
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm", this);
      this.modal = false;
    },

    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    }
  }
};
</script>
